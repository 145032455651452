<template>
  <div id="content" class="page-container">
    <md-app>
      <md-app-content :style="appContentHeight">
    <div class="grid-x grid-padding-x">
      <div class="cell small-10">
        <h4 v-html="accountingTitle"></h4>
      </div>
      <div class="cell small-2 text-right" style="margin-top: -5px">
        <md-button v-if="!printMode" class="md-icon-button md-raised md-primary" @click="onPrint">
          <md-icon>print</md-icon>
          <md-tooltip md-direction="bottom" style="font-size: medium;">
            Abrechnung jetzt drucken
          </md-tooltip>
        </md-button>
        <md-button v-if="!printMode" class="md-icon-button md-dense md-primary" @click="onClosePrintTableView">
          <md-icon>close</md-icon>
          <md-tooltip md-direction="bottom" style="font-size: medium;">
            Ansicht schliessen und zurück
          </md-tooltip>
        </md-button>
      </div>
    </div>
    <table v-if="selectedPaymentMethods">
      <thead>
      <tr>
        <th class="text-right slim-row">Status</th>
        <th v-for="method of labels.paymentMethods" :key="method" class="text-right slim-row"
            v-if="selectedPaymentMethods.includes(method)">{{ labels.paymentMethod[method] }}
        </th>
        <th class="text-right slim-row">Summe</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="state of labels.accountingStates" :key="state">
        <td v-if="getAmount(state, 'ALL') > 0" class="text-right slim-row">{{ labels.accountingState[state] }}</td>
        <td v-for="method of labels.paymentMethods" :key="method" class="text-right slim-row"
            v-if="selectedPaymentMethods.includes(method) && getAmount(state, 'ALL') > 0">
          {{ getAmount(state, method) | currency }}
        </td>
        <td v-if="getAmount(state, 'ALL') > 0" class="text-right slim-row">
          <b>{{ getAmount(state, 'ALL') | currency }}</b></td>
      </tr>
      <tr>
        <td class="text-right slim-row">alle</td>
        <td v-for="method of labels.paymentMethods" :key="method" class="text-right slim-row"
            v-if="selectedPaymentMethods.includes(method)"><b>{{ getAmount('ALL', method) | currency }}</b></td>
        <td class="text-right slim-row"><b>{{ getAmount('ALL', 'ALL') | currency }}</b></td>
      </tr>
      </tbody>
    </table>
    <table v-if="searchedAccounting">
      <thead>
      <tr class="border_bottom">
        <th>Nachname</th>
        <th>Vorname</th>
        <th>Zahlungsweise</th>
        <th>Interval</th>
        <th>Status</th>
        <th>Gesamt</th>
        <th>Nachlass</th>
        <th>Zahlungsziel</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(accounting, index) of searchedAccounting" :key="accounting.id + index" class="border_bottom">
        <td>{{ accounting.lastname }}</td>
        <td>{{ accounting.firstname }}</td>
        <td>{{ labels.paymentMethod[accounting.paymentMethod] }}</td>
        <td>{{ labels.paymentInterval[accounting.paymentInterval] }}</td>
        <td>{{ labels.accountingState[accounting.state] }}</td>
        <td>{{ accounting.totalAmount | currency }}</td>
        <td>{{ accounting.totalDiscount | currency }}</td>
        <td>{{ accounting.billingDate | moment("DD.MM.YYYY") }}</td>
      </tr>
      </tbody>
    </table>
      </md-app-content>
    </md-app>
  </div>
</template>

<script>
import jQuery from "jquery";

export default {
  name: 'BillingsPrintTable',

  mounted() {
    this.restoreUserSettings();
    this.preparePrint();
  },

  data: () => ({
    searchedAccounting: [],
    selectedPaymentMethods: [],
    accountingTitle: '',
    printMode: false,
    appContentHeight: 'height: 100%;',
  }),

  methods: {

    restoreUserSettings() {
      if (localStorage.getItem(this.user.md5 + '@searchedAccounting')) {
        this.searchedAccounting = JSON.parse(localStorage.getItem(this.user.md5 + '@searchedAccounting'));
      }
      if (localStorage.getItem(this.user.md5 + '@selectedPaymentMethods')) {
        this.selectedPaymentMethods = JSON.parse(localStorage.getItem(this.user.md5 + '@selectedPaymentMethods'));
      }
      if (localStorage.getItem(this.user.md5 + '@accountingTitle')) {
        this.accountingTitle = localStorage.getItem(this.user.md5 + '@accountingTitle');
      }
    },

    preparePrint() {
      this.$store.commit('printMode', true);
    },

    onPrint() {
      this.printMode = true;

      if (this.isDevEnv) {
        jQuery('#__vconsole').hide();
      }

      jQuery('.snotify').first().parent().hide();

      setTimeout(() => window.print(), 250);

      window.onafterprint = () => {
        if (this.isDevEnv) {
          jQuery('#__vconsole').show();
        }
        jQuery('.snotify').first().parent().show();
        this.printMode = false;
      }
    },

    onClosePrintTableView() {
      this.$store.commit('printMode', false);
      this.$router.history.go(-1);
      setTimeout(() => window.dispatchEvent(new Event('resize')), 150);
    },

    getAmount(state, method) {
      let amount = 0;
      this.searchedAccounting.forEach(accounting => {
        if ((accounting.paymentMethod === method || method === 'ALL') &&
            (accounting.state === state || state === 'ALL')) {
          amount += accounting.totalAmount;
        }
      });
      return amount;
    },
  },

  computed: {

    user() {
      return this.$store.getters.user;
    },

    labels() {
      return this.$store.getters.labels
    },

    isDevEnv() {
      return process.env.NODE_ENV === 'development';
    },
  }
}
</script>

<style scoped>

@media print {

  table {
    margin: 1rem 0 1rem 0;
  }

  tr {
    border-bottom: 1px solid #f1f1f1 !important;
  }

  #content {
    padding: 1rem;
  }

  .md-theme-dark thead, .md-theme-dark tbody, .md-theme-dark tfoot {
    border: 1px solid #f1f1f1;
    background-color: #424242;
  }

}
</style>